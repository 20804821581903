@keyframes animation-img-login {

    0%,
    100% {
        transform: translateY(-10px);
    }

    50% {
        transform: translateY(10px);
    }
}