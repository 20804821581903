.backgroundWrapper,
.divdashboard {
  /* text-align: center; */
  /* height: 400px; */
  /* overflow: hidden; */
  /* overflow: auto; */
  width: 100%;
  background: #fff;
  padding: 30px 30px 0 38px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.backgroundWrapper {
  margin-top: 40px;
}
.notifi {
  padding-bottom: 40px;
  font-size: 16px;
  font-weight: 600;
}

.timelineItem {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  margin-bottom: 20px;
}
.logoBill {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  object-fit: contain;
  /* background: rgba(71, 38, 123); */
}
.ant-timeline-item {
  padding-bottom: 20px;
}
.spantime {
  color: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 0;
  top: -21px;
}
.divdashboard {
  margin-top: 40px;
  padding: 15px 30px;
}
.itemDiv {
  position: relative;
  display: grid;
  justify-content: start;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  width: 270px;
  padding: 20px 15px;
  border-radius: 10px;
  color: #1d39c4;
  /* background: linear-gradient(135deg, #f0f5ff, #e6f4ff); */
  background: linear-gradient(135deg, #a4a4d069, #e6f4ff);
  border-color: #adc6ff;
  /* animation: gradientAnimation 4s linear infinite; */
  /* background-size: 200% 100%; */
}
/* @keyframes gradientAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
} */
.itemDiv span:nth-child(2) {
  padding-top: 15px;
  font-size: 15px;
}
.itemDiv span:nth-child(1) {
  font-size: 30px;
  font-weight: 600;
}
.iconBank {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding: 7px;
  border-radius: 50%;
  background: #a4a4d069;
  border-color: #91caff;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.iconBank svg {
  width: 20px;
  height: 20px;
  color: #0958d9;
}
